import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import features7 from "../../../public/img/features-7.png";
import features8 from "../../../public/img/features-8.png";
import features9 from "../../../public/img/features-9.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import styles from "@/styles/mobile_collection.module.scss";
import { homeLinks } from "@/data/home";

SwiperCore.use([Navigation, Pagination]);

const Mobile_Collection = () => {
  return (
    <Fragment>
      <section className={styles.collection}>
        <div className="container">
          <div className={styles.collectionheader}>
            <h2>{homeLinks.collection.title}</h2>
          </div>
          <fw-storyblock
            channel="senco"
            playlist="5zrla5"
            autoplay="true"
            branding="false"
          ></fw-storyblock>
          <Swiper
            className="swiper-navigation"
            navigation={true}
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {homeLinks.collection.sliders.map((v: any, i) => (
              <SwiperSlide key={i}>
                <Link href={v.link} passHref>
                  <div className={styles.collection_slide_item}>
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt={v.alt}
                      width={455}
                      height={607}
                    />

                    <div className={styles.port_text}>
                      <div className={styles.h3_sub}>{v.name}</div>
                      <p>Collection</p>
                    </div>

                    <div className={styles.port_btn}>
                      <div className="btn-more ">
                        Explore{" "}
                        <i className="bi bi-chevron-right text-white"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </Fragment>
  );
};

export default Mobile_Collection;

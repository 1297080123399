import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { homeLinks } from "@/data/home";
import styles from "@/styles/men_collections.module.scss";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CurrencyInr from "../common/currency-inr";

SwiperCore.use([Navigation, Pagination]);

const MenCollections = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await httpClient.get(endpoints.home.mensCollections());
      setData(response.data.data);
    } catch (error) {}
  };

  return (
    <Fragment>
      <section className={styles.men_collections}>
        <div className="container">
          <div className={styles.men_collectionsheader}>
            <h2>{homeLinks.menCollection.title}</h2>
          </div>
          <div className={styles.men_collections_container}>
            <Swiper
              className="swiper-navigation-black"
              navigation={true}
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              {data.map((v: any) => (
                <SwiperSlide key={v.category_slug}>
                  <div className={styles.men_jwellery_col}>
                    <div className={styles.men_jwellery}>
                      <Link
                        href={`/jewellery/category/${v.category_slug}`}
                        passHref
                      >
                        <div>
                          <Image
                            src={v.image}
                            className="img-fluid"
                            alt={v.name}
                            width={260}
                            height={260}
                          />
                        </div>
                      </Link>

                      <div className={styles.men_text}>
                        <div className={styles.h3_sub}>{v.name}</div>
                        <p>
                          <span className={styles.from}> starting from</span>
                        </p>
                        <p className={styles.text_color}>
                          {" "}
                          <CurrencyInr value={v.starting_price} />
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-center pt-4">
                <Link href={homeLinks.menCollection.link} passHref>
                  <div className={styles.btn_get_started}>
                    SEE ALL Collections
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MenCollections;

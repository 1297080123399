import React, { Fragment } from "react";
import Link from "next/link";
import bgimage from "../../assets/images/bg-3.jpg";
import arrow from "../../assets/images/arrow2.png";
import Image from "next/image";
import styles from "@/styles/mobile_senco_story.module.scss";
import { homeLinks } from "@/data/home";
const SencoStory = () => {
  return (
    <Fragment>
      <section>
        <div className={styles.sencostory}>
          <Image
            src={homeLinks.store.image}
            alt="150 + STORES ACROSS THE NATION"
            width={1337}
            height={550}
          />
          <div className={styles.sencostory_main}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2>{homeLinks.store.title}</h2>
                  <Link href={homeLinks.store.link} passHref>
                    <a className={styles.btn_get_started}>
                      FIND JEWELLERY STORES NEAR YOU{" "}
                      {/* <Image src={arrow} alt="LOCATE STORE" /> */}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles.history}>
            <h4>The Senco Story</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: homeLinks.store.story,
              }}
            ></div>

            <Link href={homeLinks.store.story_link} passHref>
              <div>Know More...</div>
            </Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SencoStory;

import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import features14 from "../../../public/img/features-14.png";
import features15 from "../../../public/img/features-15.png";
import features16 from "../../../public/img/features-16.png";
import styles from "@/styles/senco_exclusive.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";

SwiperCore.use([Navigation, Pagination]);

const senco_exclusive = () => {
  return (
    <Fragment>
      <section className={styles.senco_exclusive}>
        <div className="container">
          <div className={styles.senco_exclusive_header}>
            <h2 className={styles.senco_exclusive_header_text}>
              {homeLinks.sencoExclusive.title}
            </h2>
            {/* <p>{homeLinks.sencoExclusive.subTitle}</p> */}
          </div>
          <div className={styles.senco_exclusive_main}>
            <div className="row">
              {homeLinks.sencoExclusive.images.map((v: any, i) => (
                <div className="col-md-4" key={i}>
                  <Link href={v.link} passHref>
                    <div className={styles.senco_exclusive_card}>
                      <Image
                        src={v.image}
                        alt={v.name}
                        width={356}
                        height={452}
                      />
                      <span className={styles.senco_exclusive_links}>
                        <button
                          role="button"
                          className={styles.btn_get_started}
                        >
                          {v.name}
                        </button>
                      </span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="col-md-12 text-center">
            <Link href={homeLinks.sencoExclusive.link}>
              <a className={styles.btn_get_started1}>
                See More <i className="bi bi-arrow-right"></i>
              </a>
            </Link>
          </div> */}
        </div>
      </section>
    </Fragment>
  );
};

export default senco_exclusive;

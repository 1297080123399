import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { homeLinks } from "@/data/home";
import styles from "@/styles/mobile_men_collections.module.scss";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import arrow from "../../assets/images/arrow.png";
import CurrencyInr from "../common/currency-inr";

SwiperCore.use([Navigation, Pagination]);

const MobileMenCollections = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await httpClient.get(endpoints.home.mensCollections());
      setData(response.data.data);
    } catch (error) {}
  };

  return (
    <Fragment>
      <section className={styles.men_collections}>
        <div className="container">
          <div className={styles.men_collectionsheader}>
            <h2>{homeLinks.menCollection.title}</h2>
          </div>
          <div className={styles.men_collections_image}>
            <Swiper
              className="swiper-navigation-black"
              navigation={true}
              spaceBetween={10}
              slidesPerView={2}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              {data.map((v: any) => (
                <SwiperSlide key={v.category_slug}>
                  <div className={styles.men_jwellery}>
                    <Link
                      href={`/jewellery/category/${v.category_slug}`}
                      passHref
                    >
                      <div className="w-100">
                        <Image
                          src={v.image}
                          className="img-fluid"
                          alt={v.name}
                          width={350}
                          height={350}
                        />
                      </div>
                    </Link>

                    <div className={styles.men_text}>
                      <div className={styles.h3_sub}>{v.name}</div>
                      <p>starting from</p>
                      <p>
                        <CurrencyInr value={v.starting_price} />
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <Link href={homeLinks.menCollection.link} passHref>
                  <div className={styles.btn_get_started}>
                    SEE ALL Collections
                    <Image src={arrow} className="img-fluid" alt="features10" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MobileMenCollections;

import React, { Fragment, useRef, useState } from "react";

import { Scrollbar } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import Image from "next/image";
import features4 from "../../assets/images/features-4.png";
import features5 from "../../assets/images/features-5.png";
import features6 from "../../assets/images/features-6.png";

import styles from "@/styles/mobile_shopbybudget.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Grid } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";
SwiperCore.use([Navigation, Pagination]);

const Mobile_Shopbybudget = () => {
  return (
    <Fragment>
      <div className={styles.mobile_shopbybudget}>
        <div className="container">
          <div className={styles.shopbybudgetheader}>
            <h2 className={styles.shopbybudgetheader_text}>
              {homeLinks.shopByBudget.title}
            </h2>
          </div>
          <div className={styles.row}>
            {homeLinks.shopByBudget.images.map((v: any, i) => (
              <div className={styles.col_6} key={i}>
                <Link href={v.link} passHref>
                  <div className="portfolio-wrap text-center">
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt={v.alt}
                      width={237}
                      height={224}
                    />
                    <div className={styles.h5}>{v.price}</div>
                  </div>
                </Link>
              </div>
            ))}
            {/* <div className={styles.col_6}>
              <div className="portfolio-wrap text-center">
                <Image src={features5} className="img-fluid" alt="features4" />
              </div>
            </div>
            <div className={styles.col_6}>
              <div className="portfolio-wrap text-center">
                <Image src={features6} className="img-fluid" alt="features4" />
              </div>
            </div>
            <div className={styles.col_6}>
              <div className="portfolio-wrap text-center">
                <Image src={features4} className="img-fluid" alt="features4" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Mobile_Shopbybudget;
